import commonAction from 'actions/commonAction';
import PayrollReportsApi from 'api/generated/PayrollReportsApi';

export const GENERATE_1095C_PART2_REPORT_ACTION = 'GENERATE_1095C_PART2_REPORT';

export const generate1095CPart2Report = (teamId: string, year: number) =>
    commonAction(
        async () => new PayrollReportsApi().create1095CPart2Report(teamId, year),
        GENERATE_1095C_PART2_REPORT_ACTION,
        {
            toastErrorMessage: (response) =>
                `Failed to generate report. ${response.error.response?.data.errorMessage}`,
            toastSuccessMessage:
                'Successfully started report generation. The report will be added to the Team Files tab. ' +
                'An email will be sent to notify you when complete.',
        }
    );
